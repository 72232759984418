<template>
  <b-row class="inquiry-row-container relative">
    <!-- ! List user message --------------------------------------------------------------------------------------->
    <b-col cols="3" class="user-list-inquiry py-1 prohibited-content w-100" ref="list-inquiry">
      <div class="row">
        <div class="col-12">
          <div class="inquiry-list-container">
            <div class="inquiry__container-header d-flex mb-2">
              <div @click="toggleTab('email')" class="inquiry__container-name w-50 text-center float-left" :class="{'select-tab-firebase' : tab === 'email'}">
                <p :class="{'select-tab-font-weight' : tab === 'email'}">メッセージ</p>
              </div>
              <div @click="toggleTab('firebase')" class="inquiry__container-name text-center w-50 float-right" :class="{ 'select-tab-email' : tab ==='firebase' }">
                <p :class="{'select-tab-font-weight' : tab === 'firebase'}">メール</p>
              </div>
            </div>
          </div>
          <!--           tab firebase-->
          <div v-if="tab === 'email'">
            <div
                class="ml-2 px-1 text-style-black py-1 cursor-pointer d-flex justify-content-between"
                v-for="(userInQuiry, index) in userListInquiry"
                :key="index"
                :class="activeElement === index + 1 ? 'active' :'' "
                @click="getInquiryById(userInQuiry);activeElement = index + 1
                 userInQuiry.count_unread_message = ''"
            >
            <span class="user-info d-flex">
              <b-avatar v-if="userInQuiry.status && userInQuiry.status !== STATUS_DELETE_USER" size="35" :src="userInQuiry.avatar ? userInQuiry.avatar : ''"></b-avatar>
              <b-avatar v-else size="35"></b-avatar>
              <span class="user-name ml-1 last-msg-text" v-if="userInQuiry.status && userInQuiry.status !== STATUS_DELETE_USER">{{  userInQuiry.username}}</span>
              <span class="user-name ml-1 last-msg-text" v-else> 退会済み </span>
            </span>
              <span v-if="userInQuiry.count_unread_message">
                <b-badge
                    pill
                    :variant="'danger'"
                    class="mr-1 ml-auto"
                >
                  {{ userInQuiry.count_unread_message && userInQuiry.count_unread_message < 9  ? userInQuiry.count_unread_message : '' || '9+' }}
                </b-badge>
              </span>
            </div>
          </div>
          <!--          tab email-->
          <div v-else>
            <div
                class="ml-2 px-1 text-style-black py-1 cursor-pointer d-flex justify-content-between"
                v-for="(userInQuiry, index) in listUserSendEmail"
                :key="index"
                :class="activeElement === index + 1 ? 'active' :'' "
                @click="activeElement = index + 1;
                InquiryIdSendMessage = userInQuiry.inquiryId;
                idUserIsCheckClick=userInQuiry.user_id"
            >
            <span class="user-info d-flex">
              <b-avatar v-if="userInQuiry.user_status" size="35" :src="userInQuiry.avatar ? userInQuiry.avatar : ''"></b-avatar>
              <b-avatar v-else size="35"></b-avatar>
              <span class="user-name ml-1 last-msg-text" v-if="userInQuiry.user_status && userInQuiry.status !== STATUS_DELETE_USER">{{  userInQuiry.login_id}}</span>
              <span class="user-name ml-1 last-msg-text" v-else> 退会済み </span>
            </span>
            </div>
          </div>
        </div>
      </div>
    </b-col>
    <!-- ! List message --------------------------------------------------------------------------------------->
    <b-col  cols="9" class="inquiry-detail">
      <div class="row">
        <div class="col-12">
          <!--          list message firebase-->
          <div v-if="tab === 'email'" class="container-message prohibited-content" style="height: 66vh;overflow-y: auto;overflow-x: hidden" ref="msg-show-wrap">
            <div class="msg-content-wrap" v-for="(messengeList,index) in listMessenger">
              <div class="row py-1 pl-1" :key="index">
                <div class="col-1 avatar-user">
<!--                  <b-avatar size="45" :src="messengeList.is_admin && infoUser.avatar ? adminStore.avatar : infoUser.avatar"></b-avatar>-->
                  <b-avatar size="45" v-if="!messengeList.user_status || messengeList.user_status === STATUS_DELETE_USER && !messengeList.is_admin"></b-avatar>
                  <b-avatar size="45" v-else-if="!messengeList.user_status && messengeList.is_admin" :src="adminStore.avatar"></b-avatar>
                  <b-avatar size="45" v-else :src="infoUser.avatar"></b-avatar>
                </div>
                <div class="col-11 info-user-send-msg">
                  <div class="info-msg d-flex justify-content-between">
                    <div class="info-msg-name text-style-black text-style-bold" v-if="(!messengeList.user_status || messengeList.user_status === STATUS_DELETE_USER) && !messengeList.is_admin">退会済み</div>
                    <div class="info-msg-name text-style-black text-style-bold" v-else-if="messengeList.is_admin">{{  adminStore.name }}</div>
                    <div class="info-msg-name text-style-black text-style-bold" v-else>{{  infoUser.username }}</div>
                    <span class="info-msg-time-sent" v-if="messengeList.created_at">{{ formatDateTimeToJs(messengeList.created_at) }}</span>
                  </div>
                  <p class="msg-content text-style-black" v-if="messengeList.message">
                    {{ messengeList.message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--          list email -->
          <div v-else class="container-message prohibited-content" style="height: 66vh;overflow-y: auto;overflow-x: hidden" ref="msg-show-wrap">
            <div class="msg-content-wrap" v-for="(emailList,index) in listContenEmail">
              <div class="row py-1 pl-1" :key="index">
                <div class="col-1 avatar-user">
                  <b-avatar size="45" :src="emailList.avatar"></b-avatar>
                </div>
                <div class="col-11 info-user-send-msg">
                  <div class="info-msg d-flex justify-content-between">
                    <div class="info-msg-name text-style-black text-style-bold">{{  emailList.admin_name  }}</div>
                    <span class="info-msg-time-sent" v-if="emailList.created_at">{{ formatDateTimeToJs(emailList.created_at) }}</span>
                  </div>
                  <p class="msg-content text-style-black">
                    {{ emailList.message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--          form send message and contetn chat-->
          <div class="form__input-send-msg">
            <div class="row py-1 px-1">
              <div class="col-1 avatar-user">
                <b-avatar :src="message.avatar ? message.avatar : '' " size="45"></b-avatar>
              </div>
              <div class="col-11 info-admin-send-msg px-2">
                <div class="info-msg d-flex justify-content-between">
                  <div class="info-msg-name text-style-black text-style-bold ml-n1">{{ adminStore.name }}</div>
                  <span class="info-msg-time-sent">{{ currentDateTime }}</span>
                </div>
              </div>
            </div>
            <div class="py-1 px-2">
              <validation-observer v-slot="{invalid}" ref="simpleRulesCreate">
                <b-form-group id="fieldset-1" valid-feedback="Thank you!" class="mt-n1">
                  <validation-provider  #default="{ errors }" name="メッセージ" vid="message">
                    <b-form-input :class="msgErr ?'errors__validated' :''" v-model="messengerContent.message" placeholder="返信内容を入力する…" trim></b-form-input>
                    <small class="text-danger" v-if="msgErr">{{ msgErr }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-observer>
              <div class="btn-groups float-right">
                <b-button variant="outline-secondary mr-10" :disabled="disabledBtn" @click="cancerSendMsg"> キャンセル </b-button>
                <b-button variant="primary btn-send-email" :class="{'mr-0' : tab === 'firebase'}" :disabled="disabledBtn" @click="submitFormSendEmail"> メールで送信 </b-button>
                <b-button variant="primary" v-if="tab==='email'" :disabled="disabledBtn" @click="submitForm"> 送信 </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
  </div>
</template>
<script>
import {BAvatar, BButton, BCol, BFormGroup, BFormInput, BNav, BNavItem, BRow,} from "bootstrap-vue";
import {apiService} from "@/services/api.service";
import {limitPageInquiry, STATUS} from "@/constant/constant";
import {mapState} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {db} from '@/firestore'
import {nextTick} from "@vue/composition-api";
import store from "@/store";
import moment from "moment";
import { STATUS_DELETE_USER } from '@/constant/constant'
export default {
  name: "InquiryList",
  components: {
    BRow,
    BCol,
    BNav,
    BNavItem,
    BAvatar,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      currentUserDoc:null,
      activeElement:'',
      listMessenger : '',
      InquiryIdSendMessage : '',
      messengerContent: {
        message : ""
      },
      disabledBtn : false,
      doc_listener : null,
      topic_listener : null,
      user_id_sendInquiry : null,
      loadMore: null,
      canLoadMore: true,
      isValid : null,
      msgErr : '',
      ADMIN_ID :1,
      userListInquiry:[],
      listUserById : null,
      currentDateTime : null,
      infoUser: null,
      listIdUser : null,
      currentLengthTopic: limitPageInquiry,
      tab: 'email',
      // data email
      listUserSendEmail : null,
      listIdUSerSendEmail : [],
      listContenEmail : null,
      currentPage : 1,
      lastPage : '',
      deleteElement : false,
      idUserIsCheckClick : null,
      inquiryMatchFindFirebase : null,
      listTopicGetFirebase : [],
      pushNotify : {},
      STATUS_DELETE_USER
    };
  },
  //methods
  methods: {
    async getListInquiry() {
      this.addListener()
    },
    //  togger tab
    async toggleTab(tab){
      this.tab = tab;
      this.resetFormInput();
      this.activeElement = 1;
      if (this.tab === 'firebase'){
        await this.getListUserIdSendEmail(this.listIdUSerSendEmail);
      }
      if (this.tab === 'email'){
        this.currentUserDoc = this.userListInquiry[0];
        this.infoUser = this.userListInquiry[0];
      }
    },
    resetFormInput(){
      this.messengerContent.message = "";
      this.msgErr = "";
    },
    // get message firebase by Id
    async getInquiryById(doc){
      // doc.user_id is in array form, so you have to convert to string to use find function
      this.infoUser = this.userListInquiry.find(item =>{
        return item.user_id[0] === doc.user_id[0]
      });
      this.InquiryIdSendMessage = doc.inquiry_id;
      this.resetFormInput();
      this.currentUserDoc = doc;
      // When admin clicks on unread message, it will update to convert unread message to read ( value = 0 )
      this.currentUserDoc.count_unread_all.forEach(item => {
        // currently fixing admin is 1
        // if user_id === admin's id, it will not update, but only update the user with the user's id to increase the number of unread messages
        // check if user_id == id of admin is logged in and value != 0 -> have unread message-> update
        if (item.is_admin && item.value !== 0) {
          store.commit('totalCountUnreadMsg/SET_STATUS_DELETE',true);
          item.value = 0
          let indexCountUnread = this.totalNumberUnreadOfMessages.findIndex(c =>doc.chat_id === c.chat_id);
          if (indexCountUnread !== -1){
            store.commit('totalCountUnreadMsg/SPLICE_LIST_COUNT_UNREAD',indexCountUnread);
            store.commit('totalCountUnreadMsg/SET_LIST_USER_BY_ID_UNREAD_MSG',this.totalNumberUnreadOfMessages);
          }
          db.collection('topics').doc(this.currentUserDoc.chat_id).update({
            count_unread: this.currentUserDoc.count_unread_all,
            admin_reply : 0
          })
              .then(() => {
                store.commit('totalCountUnreadMsg/SET_STATUS_DELETE',false);
                // there are 2 cases. There are no messages yet, see rep and already
                // There is a message to see but no reply. divided into 2 cases. New incoming messages are in the list or not.
                // case 1 : listTopicAdminSeenNotRep elements already exist. then will have to check if this newly received message is already in that array
                if (this.listTopicAdminSeenNotRep.length) {
                  let indexReplaceTopicSeenNotRep = this.listTopicAdminSeenNotRep.findIndex(item => {
                    return item.chat_id === this.currentUserDoc.chat_id;
                  })
                  // message already in list. Update new data.
                  if (indexReplaceTopicSeenNotRep !== -1 && this.currentLengthTopic.last_message) {
                    let replace = {
                      index : indexReplaceTopicSeenNotRep,
                      inquiryReplace : this.currentUserDoc
                    }
                    store.commit('totalCountUnreadMsg/REPLACE_INQUIRY_ADMIN_SEEN_NOT_REP', replace);
                  }else{
                    // the message is not in the list. add a new array.
                    store.commit('totalCountUnreadMsg/PUSH_LIST_TOPIC_ADMIN_SEEN_NOT_REPLY',this.currentUserDoc);
                  }
                  // case 2 : when listTopicAdminSeenNotRep has no elements -> always add to array without checking
                }else {
                  store.commit('totalCountUnreadMsg/PUSH_LIST_TOPIC_ADMIN_SEEN_NOT_REPLY',this.currentUserDoc);
                }
              })
              .catch(() => {
                console.log('upload fails');
              })
        }
      })
    },
    // convert list id to string
    convertListIdToString(listId){
      let convertListIdToString = '';
      // If the array has 1 element, it will cut the array by , . and if there is only 1 element, there will be no comma,
      // so you have to convert that part to string
      if (listId.length && listId.length > 1){
        convertListIdToString = listId.join(',');
      }else{
        convertListIdToString = listId[0] + '';
      }
      return convertListIdToString;
    },
    // get info list user by id
    async getListUserById(listId){
      let convertListIdToString = this.convertListIdToString(listId);
      this.startLoading();
      try {
        let res = await apiService.get(`manage-user/getListId/${convertListIdToString}`);
        // call data get list firebase
        if (res.data.code === STATUS.SUCCESS && this.tab === 'email') {
          this.listUserById = res.data.data;
          this.userListInquiry.forEach(user=>{
            this.listUserById.find(item =>{
              if (user.user_id[0] === item.id){
                user.avatar = item.avatar;
                user.username = item.username;
                user.status = item.user_status;
              }
            })
          })
          // get the position of the current topic when a new topic is added.
          if (this.currentUserDoc){
            let indexActiveElement = this.userListInquiry.findIndex(item =>item.chat_id === this.currentUserDoc.chat_id);
            if (indexActiveElement !== -1){
              this.activeElement = indexActiveElement + 1;
            }
            this.infoUser = this.userListInquiry.find(item => {
              return item.user_id[0] === this.currentUserDoc.user_id[0]
            });
          }
          // After loading user information from the database, it will get that information to display when it first enters the screen
          if (this.currentUserDoc === null && this.userListInquiry.length){
            this.currentUserDoc = this.userListInquiry[0];
            this.InquiryIdSendMessage = this.userListInquiry[0].inquiry_id;
            this.infoUser = this.userListInquiry.find(item => {
              return item.user_id[0] === this.currentUserDoc.user_id[0]
            });
            if (this.matchUserVsInquiry.user_id){
              await this.findTopicByUserId();
            }
          }
        }
      }catch (e){
        this.notifyError(e.message)
      }finally {
        this.endLoading();
      }
    },
    // send message firebase
    async sendMessage() {
      // set the delete state so that when the document updates it will not push the updated element into the array
      store.commit('totalCountUnreadMsg/SET_STATUS_DELETE',true);
      const messageSent = {
        // because the api side doesn't have an admin avatar part, so temporarily haven't uploaded admin's avatar => add avatar for admin
        content : this.messengerContent.message,
        user_id : this.currentUserDoc.user_id[0],
      }
      // Add a new document with a generated id.
      if (this.isValid){
          this.pushNotify = {
            content :  messageSent.content,
            user_id : this.currentUserDoc.user_id[0],
            topic_id : this.currentUserDoc.chat_id,
          }
          const res = await Promise.all([
             apiService.post('inquiry-admin/send-message',messageSent),
             apiService.post('inquiry-admin/message/notify/push',this.pushNotify)
          ])
              .then(() =>{
                this.resetFormInput();
                // tìm trong mảng listIdUserSeenNotRep xem có thằng index đó không. nếu có thì xóa đi vì admin đã trả lời.
                let idReplaceAdminSeenNotRep = this.listTopicAdminSeenNotRep.findIndex(item => {
                  return item.chat_id === this.currentUserDoc.chat_id;
                })
                if (idReplaceAdminSeenNotRep !== -1){
                  store.commit('totalCountUnreadMsg/SPLICE_INQUIRY_ADMIN_SEEN_NOT_REP',idReplaceAdminSeenNotRep);
                }
                let indexReplace = this.userListInquiry.findIndex(item => {
                  return item.chat_id === this.currentUserDoc.chat_id;
                });
                //find the index in the listId array to find out which user's id sent the message, update the element position
                // so that it doesn't change position when the api calls new.
                let idReplace = this.listIdUser.findIndex(item =>{
                  return item === this.currentUserDoc.id;
                })
                if (idReplace !== -1){
                  this.listIdUser.splice(idReplace,1);
                  this.listIdUser.unshift(this.currentUserDoc.id);
                }
                if (indexReplace !== -1){
                  this.userListInquiry.splice(indexReplace,1);
                  this.userListInquiry.unshift(this.currentUserDoc);
                  this.activeElement = 1;
                }
                this.notifySuccess(STATUS.MESSENGER_ADMIN_SENT_SUCCESS);
                store.commit('totalCountUnreadMsg/SET_STATUS_DELETE',false);
              })
              .catch(e =>{
                this.notifyError(e.data.message);
              })
        }
        // update to document
        this.currentUserDoc.count_unread_all.forEach(item => {
          // hard fix admin_id = 1
          // check if any user is not admin will increase the number of unread messages
          //TODO dang fix cứng tài khoản admin là 1, sau sẽ fix lại là id của tài khoản admin đang login
          // when the admin hasn't clicked on the topic but just pressed send message -> admin has also read the message, giving the message number
          // haven't read to 0.
          if (item.is_admin && item.value) {
            let indexCountUnread = this.totalNumberUnreadOfMessages.findIndex(c =>this.currentUserDoc.chat_id === c.chat_id);
            if (indexCountUnread !== -1){
              store.commit('totalCountUnreadMsg/SPLICE_LIST_COUNT_UNREAD',indexCountUnread);
            }
            item.value = 0;
          }
        });
      db.collection('topics').doc(this.currentUserDoc.chat_id).update({
        count_unread: this.currentUserDoc.count_unread_all,
        count_unread_message : 0,
        admin_reply : 1,
        })
          .then(() => {
            this.currentUserDoc.count_unread_message = 0;
          })
          .catch(() => {
            console.log('send message fails');
          })
        this.currentUserDoc.last_message_at =  Math.floor(new Date().getTime() / 1000);
      },
    //validate form send message
    validatedForm(){
      if (this.messengerContent.message && this.messengerContent.message.length > 1024){
        this.msgErr = 'メッセージは1024文字以内で入力してください。 ';
        this.isValid = false;
      }else if (!this.messengerContent.message){
        this.msgErr = 'メッセージを入力してください。';
        this.isValid = false;
      }else if (this.messengerContent.message && this.messengerContent.message.length <= 1024) {
        this.isValid = true;
        this.msgErr = '';
      }
      return this.isValid;
    },
    // action submit form firebase
    async submitForm(){
      if (this.validatedForm()){
        await this.sendMessage();
      }
    },
    // action click cancer button
    cancerSendMsg() {
      this.resetFormInput();
    },
    // scroll get data firebase
    async onScroll() {
      let scrollDataEl = this.$refs['list-inquiry'];
      let triggerHeight = scrollDataEl.scrollTop + scrollDataEl.offsetHeight;
      // call data firebase
      if (triggerHeight >= scrollDataEl.scrollHeight && this.canLoadMore && this.tab=== 'email') {
        try {
          this.addListener();
        }catch (error){
          this.notifyError(error.message);
        }finally {
        }
      }
      // call data email
      if (triggerHeight >= scrollDataEl.scrollHeight && this.tab === 'firebase') {
        if (this.currentPage >= this.lastPage){
          return;
        }
        this.currentPage++;
        await this.getListUserIdSendEmail();
      }
    },
    // matching user id find topic
    async findTopicByUserId(){
      // check if admin clicked from the user side or not. if yes, will exist user_id. // check if user_id already exists in the list of topics retrieved or not? otherwise
      // will continue to search by user_id on firebase list
      // if the firebase list is still not there, it will create a new one, then focus on that topic.
      if (this.matchUserVsInquiry.user_id){
        let findIndexUserMatchChat = this.userListInquiry.findIndex(user => {
          return user.user_id[0] === this.matchUserVsInquiry.user_id;
        });
        // found in the list of topics got
        if (findIndexUserMatchChat !== -1){
          this.activeElement = findIndexUserMatchChat + 1;
          this.currentUserDoc = this.userListInquiry[findIndexUserMatchChat];
          this.infoUser = this.userListInquiry[findIndexUserMatchChat];
          this.InquiryIdSendMessage = this.userListInquiry[findIndexUserMatchChat].inquiry_id;
          // not found -> find all in firebase
        }else{
          let matchDoc = null;
          const query = db.collection('topics')
              .where('user_ids','array-contains',this.matchUserVsInquiry.user_id)
              .get()
              .then(async ( querySnapshot) =>{
                // the case found in firebase will get the information of that topic
                if (querySnapshot.docs.length){
                  querySnapshot.docs.map(async (doc)=>{
                    // because firebase query only gives where 1 array-contains per query so must check below condition
                    // is to filter out the new admin_ids = this.ADMIN_ID records show
                    if(doc.data().admin_ids && doc.data().admin_ids.length &&  doc.data().admin_ids[0] === this.ADMIN_ID){
                      matchDoc = doc;
                    }
                  })
                  if(matchDoc !== null) {
                    let cnt_unread_message = matchDoc.data().count_unread
                    if (typeof matchDoc.data().count_unread == 'object') {
                      matchDoc.data().count_unread.forEach((ele) => {
                        if (ele.user_id === this.ADMIN_ID && ele.is_admin) {
                          cnt_unread_message = ele.value
                        }
                      })
                    }
                    const inquiry = {
                      count_unread_message: cnt_unread_message,
                      count_unread_all: matchDoc.data().count_unread,
                      inquiry_id: matchDoc.data().id,
                      user_id: matchDoc.data().user_ids,
                      chat_id: matchDoc.id,
                      last_message: matchDoc.data().last_message,
                      created_at : matchDoc.data().created_at,
                      last_message_at : matchDoc.data().last_message_at
                    }
                    if (inquiry.user_id && inquiry.user_id.length){
                      this.startLoading();
                      try {
                        let res = await apiService.get(`manage-user/${matchDoc.data().user_ids[0]}`);
                        if (res.data.code === STATUS.SUCCESS){
                          let userInfo = res.data.data;
                          userInfo.map(user =>{
                            if (user.id === inquiry.user_id[0]){
                              inquiry.avatar = user.avatar;
                              inquiry.username = user.username;
                              inquiry.status = user.user_status;
                            }
                          })
                          this.userListInquiry.unshift(inquiry);
                          this.activeElement = 1;
                          this.InquiryIdSendMessage = this.userListInquiry[0].inquiry_id;
                          this.currentUserDoc = this.userListInquiry[0];
                          this.inquiryMatchFindFirebase = querySnapshot.docs[0].id;
                          this.infoUser = this.userListInquiry.find(item => {
                            return item.user_id[0] === this.currentUserDoc.user_id[0]
                          });
                        }
                      }catch (e){
                        this.notifyError(e.data.message);
                      }finally {
                        this.endLoading();
                      }
                    }
                  } else{
                    this.notifyError('トピックが見つかりません')
                  }
                }else{
                  // can't find the chat topic of that user will push notification
                  this.notifyError('トピックが見つかりません')
                }
              })
              .catch(msg=>{
                console.log(msg)
              })
        }
      }
      },
    // scroll get data email
    addListener() {
      let queryScroll=null
      let tempLoadMore=null
      if(this.loadMore === null) {
        queryScroll = db.collection('topics')
            .where('admin_ids', 'array-contains', this.ADMIN_ID)
            .orderBy('last_message_at', 'desc')
            .limit(this.currentLengthTopic)
      } else {
        tempLoadMore = this.loadMore;
        queryScroll = db.collection('topics')
            .where('admin_ids', 'array-contains', this.ADMIN_ID)
            .limit(this.currentLengthTopic + limitPageInquiry)
            .orderBy('last_message_at', 'desc')
            .startAfter(this.loadMore)
      }
      this.topic_listener = queryScroll.onSnapshot(querySnapshot =>{
        // put the list of topics from firestore into the list to display
        querySnapshot.docChanges().forEach((item,index)=> {
          // add
          if (item.type === "added"){
            const data = item.doc.data();
            this.loadMore = querySnapshot.docs[querySnapshot.docs.length - 1];
            if(tempLoadMore !== null && this.loadMore.id === tempLoadMore.id) {
              // If the last record doesn't change, no more can be loadedf
              this.canLoadMore = false;
            }
            let cnt_unread_message = data.count_unread
            if (typeof data.count_unread == 'object') {
              data.count_unread.forEach((ele) => {
                if (ele.user_id === this.ADMIN_ID && ele.is_admin) {
                  cnt_unread_message = ele.value
                }
              })
            }
            const inquiry = {
              count_unread_message: cnt_unread_message,
              count_unread_all: data.count_unread,
              inquiry_id: data.id,
              user_id: data.user_ids,
              chat_id: item.doc.id,
              last_message: data.last_message,
              created_at : data.created_at,
              last_message_at : data.last_message_at,
              admin_reply: data.admin_reply
            }
            this.listTopicGetFirebase.push(inquiry);
            if (index === querySnapshot.docChanges().length - 1){
              //when matching from the user screen. If I have to search the word in the firebase list,
              // there will be 1 similar record. so you have to create a new set so that you don't duplicate
              // 2 identical records ( 1 copy from firebase search and 1 copy from firebase getList about )
              this.userListInquiry = Array.from(new Set(this.listTopicGetFirebase));
              this.userListInquiry.sort((a,b) => b.last_message_at - a.last_message_at);
              this.currentLengthTopic = this.userListInquiry.length;
              // in case there is already listIdUser -> there is a new message
              if (this.listIdUser){
                this.listIdUser.unshift(inquiry.user_id.join('')-'');
              }else {
                // in case there is no listIdUser -> load data when first entering the page
                this.listIdUser = this.userListInquiry.map(item =>{
                  return item.user_id[0];
                });
              }
              this.endLoading();
            }
          }
          // update
          if (item.type === "modified"){
            // when firebase detects that a record's data has been changed, it updates the record with new data
            let dataChange = item.doc.data();
            let chat_id = item.doc.id;
            if (dataChange){
              let cnt_unread_message = dataChange.count_unread
              if (typeof dataChange.count_unread == 'object') {
                dataChange.count_unread.forEach(ele => {
                  if (ele.user_id === this.ADMIN_ID) {
                    cnt_unread_message = ele.value
                  }
                })
              }
              let inquiryUpdated = {};
              // get the number of new messages to update the array
              inquiryUpdated = {
                count_unread_message: cnt_unread_message,
                count_unread_all: dataChange.count_unread,
                last_message_at : dataChange.last_message_at,
                admin_reply : dataChange.admin_reply,
              }
              //Find the index of the element whose data has been updated
              let indexReplace = this.userListInquiry.findIndex(item => {
                return item.chat_id === chat_id
              });
              // proceed to update the data for the new element
              let cloneUserById = null;
              if (indexReplace || indexReplace === 0){
                cloneUserById = {...this.userListInquiry[indexReplace]};
                cloneUserById.count_unread_message = inquiryUpdated.count_unread_message;
                cloneUserById.count_unread_all = inquiryUpdated.count_unread_all;
                if (cloneUserById.count_unread_message > 0 ){
                  // case of new message -> bring element to the top.
                  this.userListInquiry.splice(indexReplace,1);
                  this.userListInquiry.unshift(cloneUserById);
                  let indexActiveElement = this.userListInquiry.findIndex(item =>item.chat_id === this.currentUserDoc.chat_id);
                  if (indexActiveElement !== -1){
                    this.activeElement = indexActiveElement + 1;
                  }
                }else {
                  // normal update case (send message, read message) does not change anything.
                  this.userListInquiry.splice(indexReplace,1,cloneUserById);
                }
              }
            }
          }
        })
      })
    },
    // function format datetime
    formatDateTimeToJs(d){
      let datetimeFormat = null;
        if (d){
          datetimeFormat =  moment(d).format('YYYY/MM/DD HH:mm');
          return datetimeFormat;
        }
      },
    // function tab Email
    async getListUserIdSendEmail(){
      this.startLoading();
      try {
        let res = await apiService.get(`inquiry-admin/email-inquiry-list/?page=${this.currentPage}`);
        if (res.data.code === STATUS.SUCCESS && res.data){
          this.listUserSendEmail = res.data.data;
          this.currentPage = res.data.pagination.current_page;
          this.lastPage = res.data.pagination.total_pages;
          for(let list in this.listUserSendEmail){
            this.listIdUSerSendEmail.push(this.listUserSendEmail[list].user_id);
          }
          await this.getListEmailByUserId(this.listUserSendEmail[0].user_id);
        }
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading();
      }
    },
    async getListEmailByUserId(user_id){
      this.startLoading()
      try {
        let res = await apiService.get(`inquiry-admin/email-inquiry-list/${user_id}`);
        if (res.data.code === STATUS.SUCCESS && res.data) {
          this.listContenEmail = res.data.data;
          this.listContenEmail.forEach(item=>{
            item.message = item.content;
            delete item.content;
          })
          this.resetFormInput();
        }
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading()
      }
    },
    // send message by email
    async sendMessageByEmail() {
      this.startLoading();
      this.$refs.simpleRulesCreate.reset();
      try {
        let res = await apiService.post(`inquiry-admin/user-inquiry-list/${this.InquiryIdSendMessage}/send-mail`
            ,this.messengerContent);
        if (res.data.code === STATUS.SUCCESS) {
          if (this.tab === 'firebase'){
            this.listContenEmail.push({
              admin_name : this.adminStore.name,
              last_message_at: Math.floor(new Date().getTime() / 1000),
              message : this.messengerContent.message,
            })
          }
          // tìm trong mảng listIdUserSeenNotRep xem có thằng index đó không. nếu có thì xóa đi vì admin đã trả lời.
          let idReplaceAdminSeenNotRep = this.listTopicAdminSeenNotRep.findIndex(item => {
            return item.chat_id === this.currentUserDoc.chat_id;
          })
          if (idReplaceAdminSeenNotRep !== -1){
            store.commit('totalCountUnreadMsg/SPLICE_INQUIRY_ADMIN_SEEN_NOT_REP',idReplaceAdminSeenNotRep);
          }
          this.resetFormInput();
          this.notifySuccess(STATUS.MESSENGER_CREATE_SUCCESS);
        } else if (res.data.code === STATUS.BAD_REQUEST) {
          this.$refs.simpleRulesCreate.setErrors(res.data.data);
        } else this.notifyError(res.data.message);
      } catch (e) {
        this.notifyError(e.message);
      } finally {
        this.endLoading();
      }
    },
    // submit form send email
    async submitFormSendEmail(){
      if (this.validatedForm()){
        await this.sendMessageByEmail();
      }
    },
    // scroll down topics
    scrollDownTopic(){
      nextTick(()=>{
        let divEl = this.$refs['msg-show-wrap'];
        divEl.scrollTo(0,divEl.scrollHeight);
      })
    }
  },
  // computed and watch
  computed : {
    ...mapState({
      adminStore: state => state.app.admin,
      totalNumberUnreadOfMessages : state => state.totalCountUnreadMsg.totalNumberUnreadOfMessages,
      matchUserVsInquiry : state => state.totalCountUnreadMsg.matchUserVsInquiry ?? null,
      listTopicAdminSeenNotRep : state => state.totalCountUnreadMsg.listTopicAdminSeenNotRep,
    }),
    // create a function to check if the array has userListInquiry has any more elements or not ?
    // if there is new data, the number of elements of the array will change
    checkArrUserListInquiryChange(){
      return this.userListInquiry.length && this.listIdUser.length;
    },
  },
  watch: {
    // monitor the length of the userListInquiry array to see if it changes
    // if not changed will not call the api to get the user's data
    checkArrUserListInquiryChange(){
      this.getListUserById(this.listIdUser);
    },
    // get list message by firebase
    currentUserDoc() {
      this.startLoading()
      this.listMessenger = []
      if (this.currentUserDoc) {
        if (this.doc_listener !== null) {
          this.doc_listener()
        }
        this.doc_listener = db.collection('topics')
            .doc(this.currentUserDoc.chat_id).collection('messages')
            .orderBy('created_at', 'asc')
            .onSnapshot(querySnapshot => {
              this.listMessenger = []
              querySnapshot.forEach(item => {
                const data = item.data()
                const message = {
                  avatar: data.avatar,
                  message: data.content,
                  username: data.username,
                  created_at: data.created_at * 1000,
                  sender_id :data.sender_id,
                  is_admin: data.is_admin,
                }
                if (!message.is_admin){
                  message.user_status = this.currentUserDoc.status;
                }
                this.listMessenger.push(message)
                this.disabledBtn = false;
              })
              this.endLoading();
              this.scrollDownTopic()
            })
      } else {
        this.listMessenger = []
      }
    },
    // when idUserIsCheckClick changes, call back to api. Otherwise, do not do anything
    idUserIsCheckClick(){
      this.getListEmailByUserId(this.idUserIsCheckClick);
    },

  },
  //lifecycle
  async created() {
    await this.getListInquiry();
    this.activeElement = 1
  },
  mounted() {
    let scrollDataEl = this.$refs['list-inquiry'];
    scrollDataEl.addEventListener('scroll',()=>{
      this.onScroll();
    })
    //get realtime
    setInterval(() => {
      let timeRealtime = Math.floor(new Date().getTime());
      timeRealtime = new Date(timeRealtime);
      this.currentDateTime = moment(timeRealtime).format('YYYY/MM/DD HH:mm:ss')
    }, 1000);
  },
  //destroy action
  beforeDestroy() {
    this.topic_listener();
    this.doc_listener();
    store.dispatch('totalCountUnreadMsg/matchUserVsInquiryChat',null);
  },
};
</script>

<style lang="scss" scoped>
.mr-10 {
  margin-right: 10px;
}
.active {
  background-color: #C9DCFF;
  border-radius: 10px;
}

.msg-content{
  width: 100%;
}
.msg-content .message-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  display: block;
}
.text-style-black{
  font-size: 16px;
  color: #000000;
  font-family: "Noto Sans", sans-serif;
}
.text-style-bold{
  font-weight: 800;
}
.btn-send-email{
  margin-right: 9px;
}
.inquiry-detail{
  background-color: #ffffff;
  border-left: 1px solid #F5F5F5;
}
.msg-content-wrap{
  border-bottom: 2px solid #e7e7e7;
}
.inquiry-detail{
  padding-left: 0;
}
.user-list-inquiry{
  overflow-y: auto;
}
.btn-groups{
  margin-top: -7px;
}
.form__input-send-msg{
  border-top: 1px solid #cccccc;
}
.inquiry-list-container{
  padding-right: 0;
  overflow-x: hidden;
  .inquiry__container-header{
    border-bottom: 1px solid #E0E0E0;
    padding-top: 5px;
    cursor: pointer;
  }
  .inquiry__container-name p{
    margin-bottom: 7px;
    color: #000000;
  }
  .select-tab-firebase::after{
    display: block;
    content: '';
    width: 50%;
    height: 2px;
    background: #377DFF;
    position: absolute;
    top: 32px;
    left: 0;
  }
  .select-tab-email::after{
    display: block;
    content: '';
    width: 50%;
    height: 2px;
    background: #377DFF;
    position: absolute;
    top:32px;
    right: 0;
  }
  .select-tab-font-weight{
    font-weight: 800;
  }
}
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px){
  .user-list-inquiry{
    height: 85vh;
  }
  .info-user-send-msg{
    margin-left: -1rem;
  }
  .info-admin-send-msg{
    margin-left: -0.7rem;
  }
  .info-msg-time-sent{
    margin-left: -2rem;
  }
  .btn-groups{
    margin-top: 0.3rem;
  }

}
//laptop > 16
@media only screen
and (min-width: 1370px)
and (max-width: 1600px) {
  .user-list-inquiry{
    height: 85vh;
    //height: 180px;
  }
  .info-user-send-msg{
    margin-left: -1rem;
  }
  .info-admin-send-msg{
    margin-left: -0.8rem;
  }
  .btn-groups{
    margin-bottom: 0.4rem;
  }
}
//laptop <= 14
@media only screen
and (min-width: 1030px)
and (max-width: 1366px){
  .user-list-inquiry{
    height: 85vh;
  }
  .info-user-send-msg{
    margin-left: -1rem;
  }
  .info-admin-send-msg{
    margin-left: -0.6rem;
  }
  .info-msg-time-sent{
    margin-left: -2rem;
  }
  .btn-groups{
    margin-top: 0.3rem;
  }
}
//pc
@media only screen and (min-width: 1600px){
  .user-list-inquiry{
    height:  90vh;
  }
  .info-user-send-msg{
    margin-left: -3rem;
  }
  .info-admin-send-msg{
    margin-left: -2.5rem;
  }
  .info-msg-time-sent{
    margin-left: -2rem;
  }
  .btn-groups{
    margin-top: 0.3rem;
  }
}
</style>

